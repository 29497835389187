*{
  margin: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  display: flex;
}

.btn{
  background-color: #2460f8;
  color: white;
  font-size: 25px;
  padding: 5px 10px;
  border: none;
  -webkit-transition: background-color .4s; /* For Safari 3.0 to 6.0 */
  /*transition: background-color .4s; !* For modern browsers *!*/
  transition: all 0.3s ease-in-out;
  cursor: pointer;

}

.btn:hover {
  background-color: #a7b7de;
  box-shadow: 1px 1px 1px #4e608a;
}