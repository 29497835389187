.task{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #f8f8f8;
    margin-bottom: 20px;
}
.task-text{
    flex-basis: 70%;
    font-size: 25px;
}
.close {
    width: 32px;
    height: 32px;
    opacity: 0.3;
    display: inline;
    -webkit-transition: background .2s; /* For Safari 3.0 to 6.0 */
    transition: opacity 0.2s; /* For modern browsers */
}
.close:hover {
    opacity: 1;
}
.close:before, .close:after {position: absolute;
    display: block;
    content: ' ';
    height: 25px;
    width: 2px;
    background-color: #333;
}
.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}

.checkbox {
    border: 1px solid darkblue;
    height: 20px;
    width: 20px;
    display: inline-block;
    cursor:pointer;
}

.check:hover{
    background-color: #4d4dce;
}

.check {
    background: white;
    display: block;
    margin: 2px;
    height: 14px;
    width: 14px;
    -webkit-transition: background .2s; /* For Safari 3.0 to 6.0 */
    transition: background .2s; /* For modern browsers */
}
.active{
    background-color: darkblue !important;
}