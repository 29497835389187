.main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
}

.todo-wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    background-color: #dcdcdc;
}

.todo-main{
    width: 75%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f1f0f0;
    justify-content: space-between;
}

.list-header{
    flex-basis: 10%;
    min-height: 65px;
    background-color: #f1f0f0;
    padding: 20px;
    margin-bottom: 20px;
}

.todo-list{
    background-color: #f1f0f0;
    flex-basis: 90%;
    padding: 20px;
}

.header,
.footer {
    flex-shrink: 0;
    background-color: #dcdcdc;
    padding: 10px;
    min-height: 75px;
}